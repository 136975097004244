import React, { useEffect ,useState} from "react";
import './certification-developpeur-web-and-solutions.css';
import { SuperBlockStages, SuperBlocks, superBlockOrder } from '../../../shared/config/superblocks';
import { useTranslation } from 'react-i18next';
import { SuperBlockIcon } from '../assets/icons/superblock-icon';
import LinkButton from '../assets/icons/link-button';
import { Link, Spacer } from '../components/helpers';
import { getSuperBlockTitleForMap } from '../utils/superblock-map-titles';
import { getSuperBlockIntroForMap } from '../utils/superblock-map-intro';
import { showUpcomingChanges } from '../../config/env.json';
import Login from '../components/Header/components/login';
import LoginToSee from '../components/Header/components/logintosee';
import { useSelector } from 'react-redux';
import { isSignedInSelector, userSelector } from "../redux/selectors";
import img from "./cc.svg"; 
import { User } from "../redux/prop-types";


interface MapProps {
  forLanding?: boolean;
  user?: User;
}

const linkSpacingStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const coreCurriculum = [
  // ...superBlockOrder[SuperBlockStages.FrontEnd],
  // Uncomment other stages as needed
  ...superBlockOrder[SuperBlockStages.Backend],
  // ...superBlockOrder[SuperBlockStages.Python],
  // ...superBlockOrder[SuperBlockStages.Professional],
  // ...superBlockOrder[SuperBlockStages.Legacy],
  // ...superBlockOrder[SuperBlockStages.New],
];

interface MapLiProps {
  superBlock: SuperBlocks;
  landing?: boolean;
  user?: User;
}





function MapLi({ superBlock, landing = false, user }: MapLiProps) {
  const isSignedIn = useSelector(isSignedInSelector);
  const userData = useSelector(userSelector);
  const currentUser = user || userData;

  // State to handle alert visibility
  const [showAlert, setShowAlert] = useState(false);

  // Get the title for the superBlock
  const superBlockTitle = getSuperBlockTitleForMap(superBlock);

  // Certifications sequence logic
  const hasCompletedDataVis = currentUser?.is2018DataVisCert;
  const hasCompletedRelationalDatabase = currentUser?.isRelationalDatabaseCertV8;
  const hasCompletedDataAnalysis = currentUser?.isDataAnalysisPyCertV7;

  // Set card states based on completion
  const isDataVis = superBlockTitle === "Data Visualization Certification";
  const isRelationalDatabase = superBlockTitle === "Relational Database Certification";
  const isDataAnalysis = superBlockTitle === "Data Analysis with Python Certification";

  const canAccessRelationalDatabase = hasCompletedDataVis;
  const canAccessDataAnalysis = hasCompletedRelationalDatabase;

  const isDisabled =
    (isRelationalDatabase && !canAccessRelationalDatabase) ||
    (isDataAnalysis && !canAccessDataAnalysis);

  // Alert message for locked cards
  const alertMessage = "You need to complete the previous certification to access this content.";

  // Handle card button click
  const handleClick = (e: React.MouseEvent) => {
    if (isDisabled) {
      e.preventDefault(); // Prevent page reload
      setShowAlert(true);  // Trigger the alert (if necessary)
    }
  };


   useEffect(()=>{
    console.log(currentUser?.is2018DataVisCert)
    console.log(currentUser?.isDataAnalysisPyCertV7)
   },[])
  return (
    <li
      data-test-label="curriculum-map-button"
      className={`${isDisabled ? "card disabled-card" : "card"}`}
    >
      {isSignedIn ? (
        <Link
        className="card-link"
        to={isDisabled ? "#" : `/learn/${superBlock}/`}
        target={isDisabled ? "_self" : "_self"} // You can also use "_self" for both cases, as it's the default.
        onClick={(e) => {
          if (isDisabled) {
            e.preventDefault(); // Prevent the navigation if the card is disabled
          }
        }}
        >
          <div
            className={`${
              isDisabled ? "disabled-container card-container" : "card-container"
            }`}
          >
            <SuperBlockIcon className="map-icon" superBlock={superBlock} />
            <div className="card-body">
              <div className="card-title">{superBlockTitle}</div>
              <p className="card-text">
                {getSuperBlockIntroForMap(superBlock)}
              </p>
              <button
                onClick={handleClick} // Call the handleClick function
                disabled={isDisabled} // This makes the button truly disabled
                className={`${isDisabled ? "disabled-button" : "details-button"}`}
              >
                {isDisabled ? (
                  <>
                    <svg
                      width="25px"
                      height="auto"
                      viewBox="0 0 24.00 24.00"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#ffffff"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M12 14.5V16.5M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C15.9474 10 16.5286 10 17 10.0288M7 10.0288C6.41168 10.0647 5.99429 10.1455 5.63803 10.327C5.07354 10.6146 4.6146 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C18.0057 10.1455 17.5883 10.0647 17 10.0288M7 10.0288V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V10.0288"
                          stroke="#ffffff"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </g>
                    </svg>
                  </>
                ) : (
                  "See More Details"
                )}
              </button>
            </div>
          </div>
        </Link>
      ) : (
        <div className={`${isDisabled ? "disabled-container" : "card-container"}`}>
          <SuperBlockIcon className="map-icon" superBlock={superBlock} />
          <div className="card-body">
            <div className="card-title">{superBlockTitle}</div>
            <p className="card-text">{getSuperBlockIntroForMap(superBlock)}</p>
            <button className={`${isDisabled ? "disabled-button" : "details-button"}`}>
              <LoginToSee />
            </button>
          </div>
        </div>
      )}

      <style>
        {`
          .disabled-button {
            width: 100%;
            padding: 10px 30px;
            gap: 10px;
            border-radius: 10px;
            background: #614D99;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .disabled-button:hover {
            background: #614D99;
          }
        `}
      </style>
    </li>
  );
}


const CertificationDveloppeurData = () => {
  const { t } = useTranslation(); // Use the useTranslation hook inside the component

  return (
    

    <>
    <section className="intro-category-new container">
        <div className="content">
          <div className="left">
            <h1>Data Developer Certification</h1>
            <Spacer size="medium" />

            <p>
            The Data Developer Certification is designed for professionals who want to specialize in managing and developing complex data systems. This certification provides an in-depth understanding of database design, data modeling, and data manipulation techniques. Participants will learn how to work with different types of databases, including relational, NoSQL, and distributed systems, ensuring they are equipped to handle both structured and unstructured data in today's data-driven environments. <br />
              <br />
              Beyond technical skills, the certification emphasizes the integration of data management strategies with business goals. Certified data developers are trained to extract, transform, and load (ETL) data efficiently, ensuring it is clean, consistent, and ready for analysis. They also gain expertise in working with large datasets, optimizing queries for performance, and ensuring data security, making them invaluable assets to organizations seeking to leverage their data for strategic decision-making. <br />
              <br />
              Completing this certification demonstrates a mastery of data management principles and a commitment to staying updated in the rapidly evolving field of data development. Whether your goal is to become a data architect, support data-driven applications, or lead data teams, this certification provides the skills and knowledge required to thrive in a data-focused career, helping you become a pivotal part of any organization’s digital transformation efforts.
            </p>
          </div>
          <div className="right">
            <img src={img} alt="thumb" loading="lazy" />
          </div>
        </div>
      </section>

      <style jsx>{`
        .intro-category-new {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 2rem;
          margin-top: 10px;
        }

        .intro-category-new .content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 2rem;
        }

        .intro-category-new .left {
          text-align: start;
          max-width: 800px;
        }

        .intro-category-new .left h1 {
          font-family: Inter;
          font-size: 36px;
          font-weight: 600;
          line-height: 43.57px;
          text-align: left;
          color: black;
        }

        .intro-category-new .left p {
          font-family: Inter;
          font-size: 16px;
          font-weight: 400;
          line-height: 19.36px;
          text-align: justify;
          color: #797979;
        }

        .intro-category-new .right img {
          height: auto;
          max-width: 100%;
        }

        @media (max-width: 1024px) {
          /* Tablet */
          .intro-category-new {
            padding: 1.5rem;
          }

          .intro-category-new .content {
            flex-direction: column;
            gap: 1rem;
          }

          .intro-category-new .left h1 {
            font-size: 28px;
            line-height: 36px;
          }

          .intro-category-new .left p {
            font-size: 14px;
          }
        }

        @media (max-width: 768px) {
          /* Mobile */
          .intro-category-new {
            padding: 1rem;
          }

          .intro-category-new .content {
            flex-direction: column;
            gap: 1rem;
          }
          .intro-category-new .right img {
            display: none;
          }
          .intro-category-new .left h1 {
            font-size: 24px;
            line-height: 32px;
          }

          .intro-category-new .left p {
            font-size: 14px;
          }
        }
      `}</style>
    
    <section className=" container">
      <div className='map-ui' data-test-label='curriculum-map'>
        <ul>
          {coreCurriculum.map((superBlock, i) => (
            <MapLi key={i} superBlock={superBlock} landing={false} />
          ))}
        </ul>

        <Spacer size='medium' />

        {showUpcomingChanges && (
          <>
            <h2 className='content-title-cards'>{t('landing.upcoming-heading')}</h2>
            <ul>
              {superBlockOrder[SuperBlockStages.Upcoming].map((superBlock, i) => (
                <MapLi key={i} superBlock={superBlock} landing={false} />
              ))}
            </ul>
          </>
        )}
      </div>
    </section></>
  );
};

export default CertificationDveloppeurData;
